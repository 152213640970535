<template>
	<v-card outlined>
		<v-card-title>
			Stelling {{ question.position }} : {{ question.title }}
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" class="grey lighten-5 text-body-1">
					{{ question.question }}
				</v-col>
				<v-col cols="12" md="5" offset-md="1">
					<v-row no-gutters>
						<v-col cols="12"> Mate van energie </v-col>
						<v-col cols="12">
							<v-radio-group
								v-model="question.answer.preference"
								row
							>
								<v-radio
									v-for="i in 5"
									:key="i"
									:value="i"
									:label="`${i}`"
								></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
					<v-row><v-col></v-col></v-row>
					<v-row no-gutters>
						<v-col cols="12"> Mate van ontwikkeling </v-col>
						<v-col cols="12">
							<v-radio-group
								v-model="question.answer.latency"
								row
								@change="dataModified = true"
							>
								<v-radio
									v-for="item in radioItems"
									:key="item.value"
									:value="item.value"
									:label="item.text"
								></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
		<v-divider />
		<v-card-actions>
			<v-spacer />
			<v-btn
				text
				plain
				v-if="question.position > 1"
				@click="previousStep()"
			>
				<v-icon left>mdi-chevron-left</v-icon>
				vorige stelling
			</v-btn>
			<v-spacer />
			<v-btn
				v-if="
					question.answer.latency > 0 &&
					question.answer.preference > 0 &&
					question.position < count
				"
				text
				plain
				color="primary"
				@click="nextStep()"
			>
				volgende stelling
				<v-icon right>mdi-chevron-right</v-icon>
			</v-btn>
			<v-btn
				class="primary white--text px-10"
				v-if="
					question.answer.latency > 0 &&
					question.answer.preference > 0 &&
					question.position === count
				"
				@click="completeSurvey"
			>
				ik ben gereed
			</v-btn>

			<v-spacer />
		</v-card-actions>
	</v-card>
</template>

<script>
	import { updateDoc, doc } from "firebase/firestore";
	import { db } from "@/services/firebase.js";
	import { mapGetters } from "vuex";
	export default {
		name: "SurveyCard",
		props: {
			question: Object,
			surveyId: String,
			count: Number,
		},
		created() {},
		data() {
			return {
				dataModified: false,
				radioItems: [
					{ text: "Laag", value: 1 },
					{ text: "Medium", value: 2 },
					{ text: "Hoog", value: 3 },
				],
			};
		},
		computed: {
			...mapGetters({
				userInfo: "session/userInfo",
			}),
		},
		methods: {
			nextStep() {
				this.updateAnswer().then(() => {
					this.$emit("nextStep");
				});
			},
			previousStep() {
				this.updateAnswer().then(() => {
					this.$emit("previousStep");
				});
			},
			async updateAnswer() {
				if (this.dataModified) {
					await updateDoc(
						doc(
							db,
							"surveys",
							this.surveyId,
							"questions",
							this.question.id
						),
						{
							answer: {
								preference: this.question.answer.preference,
								latency: this.question.answer.latency,
							},
						}
					);
					this.dataModified = false;
				}
			},
			async completeSurvey() {
				try {
					await this.updateAnswer();
					await updateDoc(doc(db, "surveys", this.surveyId), {
						status: "READY",
					});
					this.$store.dispatch("session/showSuccess");
					this.$router.go(-1);
				} catch (error) {
					this.$store.dispatch("session/showBottomMessage", error);
				}
			},
		},
	};
</script>

<style>
</style>