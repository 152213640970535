<template>
	<v-container fluid class="pa-0">
		<v-toolbar elevation="1" :style="stickyTop">
			<v-toolbar-title v-if="survey.coachee">Interview met {{ survey.coachee.name }}
			</v-toolbar-title>
			<v-spacer />
			<pdfButton :data="{ survey, questions, surveyId }" />
			<v-spacer />
			<v-icon @click="$router.go(-1)">mdi-close-circle</v-icon>
		</v-toolbar>
		<v-progress-linear :value="perc" />
		<v-container v-if="questions.length > 0">
			<v-row justify="center">
				<v-col cols="12" lg="10" class="pt-lg-12">
					<SurveyCard :question="questions[currentPosition - 1]" :surveyId="surveyId" :count="questions.length"
						v-on:previousStep="currentPosition -= 1" v-on:nextStep="currentPosition += 1" />
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>
<script>
import SurveyCard from "@/components/SurveyCard";
import { db } from "@/services/firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import pdfButton from "@/components/pdfButton.vue";
export default {
	name: "Survey",
	components: { SurveyCard, pdfButton },
	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 1,
			};
		},
		perc() {
			return (this.currentPosition / this.questions.length) * 100;
		},

	},
	data() {
		return {
			currentPosition: 1,
			surveyId: "",
			survey: {},
			questions: [],
		};
	},

	created() {
		// watch the params of the route to fetch the data again
		this.$watch(
			() => this.$route.params,
			(_new, _old) => {
				if (
					_new &&
					_new.id &&
					_new != _old &&
					this.$route.name === "Survey"
				) {
					this.surveyId = _new.id;
					this.fetchSurvey(_new.id).then(() => {
						this.currentPosition = 1;
					});
				}
			},
			// fetch the data when the view is created and the data is
			// already being observed
			{ immediate: true }
		);
	},
	methods: {
		async fetchSurvey(_surveyId) {
			const docRef = doc(db, "surveys", _surveyId);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				this.survey = docSnap.data();
				const subColRef = collection(
					db,
					"surveys",
					_surveyId,
					"questions"
				);
				const res = await getDocs(subColRef);
				this.questions = res.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				this.questions.sort((a, b) => {
					return a.position - b.position;
				});
			}
		},
	},
};
</script>
