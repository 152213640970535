var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Stelling "+_vm._s(_vm.question.position)+" : "+_vm._s(_vm.question.title)+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"grey lighten-5 text-body-1",attrs:{"cols":"12","md":"6"}},[_vm._v(" "+_vm._s(_vm.question.question)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"5","offset-md":"1"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Mate van energie ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.question.answer.preference),callback:function ($$v) {_vm.$set(_vm.question.answer, "preference", $$v)},expression:"question.answer.preference"}},_vm._l((5),function(i){return _c('v-radio',{key:i,attrs:{"value":i,"label":`${i}`}})}),1)],1)],1),_c('v-row',[_c('v-col')],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Mate van ontwikkeling ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){_vm.dataModified = true}},model:{value:(_vm.question.answer.latency),callback:function ($$v) {_vm.$set(_vm.question.answer, "latency", $$v)},expression:"question.answer.latency"}},_vm._l((_vm.radioItems),function(item){return _c('v-radio',{key:item.value,attrs:{"value":item.value,"label":item.text}})}),1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.question.position > 1)?_c('v-btn',{attrs:{"text":"","plain":""},on:{"click":function($event){return _vm.previousStep()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" vorige stelling ")],1):_vm._e(),_c('v-spacer'),(
				_vm.question.answer.latency > 0 &&
				_vm.question.answer.preference > 0 &&
				_vm.question.position < _vm.count
			)?_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" volgende stelling "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(
				_vm.question.answer.latency > 0 &&
				_vm.question.answer.preference > 0 &&
				_vm.question.position === _vm.count
			)?_c('v-btn',{staticClass:"primary white--text px-10",on:{"click":_vm.completeSurvey}},[_vm._v(" ik ben gereed ")]):_vm._e(),_c('v-spacer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }